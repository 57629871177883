import { useState, useEffect } from "react";
import axios from "axios";
import RequestedSong from "./RequestedSong";

const Requests = ({ handleToggleRequests }) => {
  const [data, setData] = useState({ requests: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("https://stats.goodmusic.am/requests");
        console.log("result: ", result.data);
        setData(result.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setData, setError, setLoading]);

  return (
    <div className="requests">
      <div className="requestsTopBar">
        <h4>Top Requests</h4>
        <div className="close" onClick={handleToggleRequests}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6421 12.3044L19.9463 7.00013C20.0865 6.85997 20.0865 6.63266 19.9463 6.49162L18.295 4.84029C18.1549 4.70013 17.9275 4.70013 17.7865 4.84029L12.4822 10.1446L7.17799 4.83937C7.03783 4.69921 6.81051 4.69921 6.66948 4.83937L5.01815 6.4907C4.87799 6.63086 4.87799 6.85817 5.01815 6.99921L10.3224 12.3044L5.01723 17.6087C4.87707 17.7488 4.87707 17.9761 5.01723 18.1172L6.66856 19.7685C6.80872 19.9086 7.03603 19.9086 7.17707 19.7685L12.4822 14.4642L17.7865 19.7685C17.9267 19.9086 18.154 19.9086 18.295 19.7685L19.9463 18.1172C20.0865 17.977 20.0865 17.7497 19.9463 17.6087L14.6421 12.3044Z"
              fill="#EAEAEA"
            />
          </svg>
        </div>
      </div>
      <div className="requestedSongs">
        {data.requests.map((r, i) => {
          console.log("r", r);
          return <RequestedSong key={i} request={r} />;
        })}
      </div>
    </div>
  );
};

export default Requests;
