import React from "react";
import ReactDOM from "react-dom/client";
import AudioPlayer from "./components/AudioPlayer";
//import TagManager from "react-gtm-module";

// css
import "./styles/index.css";
import "./styles/customize-progress-bar.css";

// const tagManagerArgs = {
//   gtmId: "GTM-TMMWPTBG",
// };
// TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AudioPlayer />
  </React.StrictMode>
);
