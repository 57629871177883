import { useState, useEffect } from "react";
import axios from "axios";

const numPlays = 7124;

const Marquee = () => {
  const rndInt = Math.floor(Math.random() * 5) + 1;
  const [data, setData] = useState({ locations: [] });
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(null);

  const [plays, setPlays] = useState(numPlays);
  const [formattedPlays, setFormattedPlays] = useState(
    numPlays.toLocaleString("en-US")
  );

  const [listeners, setListeners] = useState(rndInt);

  useEffect(() => {
    const rndInt = Math.floor(Math.random() * 9500) + 1;

    const interval = setInterval(function () {
      let c = plays + 1;
      setPlays(c);
      const formattedNumber = c.toLocaleString("en-US");
      setFormattedPlays(formattedNumber);
    }, 15000 + rndInt);

    return function () {
      clearTimeout(interval);
    };
  }, [plays, setPlays, setFormattedPlays]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("https://stats.goodmusic.am/listeners");
        result.data.locations.unshift(
          "CITIES</span><span>CURRENTLY</span><span>TUNED</span><span>IN"
        );
        let ls = [];
        for (let x = 0; x < result.data.locations.length; x++) {
          ls[x] = {
            value: result.data.locations[x],
            label:
              "<span>" +
              result.data.locations[x].replace(" ", "</span><span>", "g") +
              "</span>",
          };
        }
        result.data.locations = ls;

        setData(result.data);
        setListeners(Math.max(1, result.data.total));
        setFinished(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setListeners, setData, setError, setLoading, setFinished]);

  return (
    <div className={finished ? "marquee animating" : "marquee"}>
      <div className="marquee__group">
        <div className="plays">
          <span>{formattedPlays}</span>
          <span>plays</span>
        </div>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <div className="listeners">
          <span>{listeners}</span>
          <span>listener{listeners > 1 ? "s" : ""}</span>
        </div>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        {data.locations.map((location, i) => (
          <div className="innerMarquee" key={i}>
            {location.value === "(other)" || location.value === "" ? (
              <div className="location">
                <span>PLANET</span>
                <span>EARTH</span>
              </div>
            ) : (
              <div
                className="location"
                dangerouslySetInnerHTML={{
                  __html: location.label,
                }}
              ></div>
            )}

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>
          </div>
        ))}
      </div>
      <div className="marquee__group">
        <div className="plays">
          <span>{formattedPlays}</span>
          <span>plays</span>
        </div>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <div className="listeners">
          <span>{listeners}</span>
          <span>listener{listeners > 1 ? "s" : ""}</span>
        </div>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        {data.locations.map((location, i) => (
          <div className="innerMarquee" key={i}>
            {location.value === "(other)" || location.value === "" ? (
              <div className="location">
                <span>PLANET</span>
                <span>EARTH</span>
              </div>
            ) : (
              <div
                className="location"
                dangerouslySetInnerHTML={{
                  __html: location.label,
                }}
              ></div>
            )}

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>
          </div>
        ))}
      </div>
      <div className="marquee__group">
        <div className="plays">
          <span>{formattedPlays}</span>
          <span>plays</span>
        </div>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <div className="listeners">
          <span>{listeners}</span>
          <span>listener{listeners > 1 ? "s" : ""}</span>
        </div>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        {data.locations.map((location, i) => (
          <div className="innerMarquee" key={i}>
            {location.value === "(other)" || location.value === "" ? (
              <div className="location">
                <span>PLANET</span>
                <span>EARTH</span>
              </div>
            ) : (
              <div
                className="location"
                dangerouslySetInnerHTML={{
                  __html: location.label,
                }}
              ></div>
            )}

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>
          </div>
        ))}
      </div>
      <div className="marquee__group">
        <div className="plays">
          <span>{formattedPlays}</span>
          <span>plays</span>
        </div>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <div className="listeners">
          <span>{listeners}</span>
          <span>listener{listeners > 1 ? "s" : ""}</span>
        </div>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
            fill="black"
          />
        </svg>

        {data.locations.map((location, i) => (
          <div className="innerMarquee" key={i}>
            {location.value === "(other)" || location.value === "" ? (
              <div className="location">
                <span>PLANET</span>
                <span>EARTH</span>
              </div>
            ) : (
              <div
                className="location"
                dangerouslySetInnerHTML={{
                  __html: location.label,
                }}
              ></div>
            )}

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>

            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.27263 6.63459L13.964 1.94325L15.0568 3.03604L10.3654 7.72737H17V9.27263H10.3654L15.0568 13.964L13.964 15.0568L9.27263 10.3654V17H7.72737V10.3654L3.03604 15.0568L1.94325 13.964L6.63458 9.27263H0V7.72737H6.63458L1.94325 3.03604L3.03604 1.94325L7.72737 6.63459V0H9.27263V6.63459Z"
                fill="black"
              />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
