import { useState, useEffect } from "react";

const TopBar = () => {
  const [plays, setPlays] = useState(2934344);
  const [formattedPlays, setFormattedPlays] = useState("0");

  useEffect(() => {
    setTimeout(() => {
      let c = plays + 1;
      setPlays(c);
      const formattedNumber = c.toLocaleString("en-US");
      setFormattedPlays(formattedNumber);
    }, 1000);
  }, [plays, setPlays, setFormattedPlays]);

  return (
    <div className="top__bar">
      <svg
        width="157"
        height="40"
        viewBox="0 0 157 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.8755 20.9728C39.3531 20.9728 39.7246 21.3443 39.7246 21.8219V37.9022C39.7246 38.3798 39.3531 38.7513 38.8755 38.7513H26.6163C24.5996 39.4412 22.4768 39.7596 20.2479 39.7596C9.63386 39.7596 0.824219 31.6399 0.824219 20.2298C0.824219 8.7667 9.63386 0.646973 20.2479 0.646973C30.4904 0.646973 39.0347 8.12986 39.7246 18.85C39.7246 19.3277 39.3531 19.7522 38.8755 19.7522H23.1667C22.8483 19.7522 22.5299 19.5399 22.3707 19.2215C22.0523 18.2663 21.1501 17.6825 20.2479 17.6825C19.0803 17.6825 17.9659 18.7439 17.9659 20.2298C17.9659 21.6627 19.0803 22.7241 20.2479 22.7241C21.2031 22.7241 21.84 22.2996 22.2645 21.5566C22.4238 21.1851 22.8483 20.9728 23.2198 20.9728H38.8755Z"
          fill="#EAEAEA"
        />
        <path
          d="M59.9468 0.646973C70.5608 0.646973 79.4235 8.7667 79.4235 20.2298C79.4235 31.6399 70.5608 39.7596 59.9468 39.7596C49.3328 39.7596 40.5232 31.6399 40.5232 20.2298C40.5232 8.7667 49.3328 0.646973 59.9468 0.646973ZM59.9468 22.7241C61.1674 22.7241 62.2819 21.6627 62.2819 20.2298C62.2819 18.7439 61.1674 17.6825 59.9468 17.6825C58.7793 17.6825 57.6648 18.7439 57.6648 20.2298C57.6648 21.6627 58.7793 22.7241 59.9468 22.7241Z"
          fill="#EAEAEA"
        />
        <path
          d="M99.1275 0.646973C109.742 0.646973 118.604 8.7667 118.604 20.2298C118.604 31.6399 109.742 39.7596 99.1275 39.7596C88.5135 39.7596 79.7038 31.6399 79.7038 20.2298C79.7038 8.7667 88.5135 0.646973 99.1275 0.646973ZM99.1275 22.7241C100.348 22.7241 101.463 21.6627 101.463 20.2298C101.463 18.7439 100.348 17.6825 99.1275 17.6825C97.9599 17.6825 96.8455 18.7439 96.8455 20.2298C96.8455 21.6627 97.9599 22.7241 99.1275 22.7241Z"
          fill="#EAEAEA"
        />
        <path
          d="M138.627 1.6553C148.285 1.6553 156.777 8.7667 156.777 20.2298C156.777 31.693 148.285 38.7513 138.627 38.7513H120.795C120.37 38.7513 119.999 38.3798 119.999 37.9553V2.50443C119.999 2.07987 120.37 1.6553 120.795 1.6553H138.627ZM137.671 18.107V22.3527C137.671 22.7241 138.043 22.9895 138.414 22.8303C139.316 22.4588 139.953 21.4505 139.953 20.2298C139.953 19.0092 139.263 18.0009 138.414 17.6294C138.043 17.4702 137.671 17.7356 137.671 18.107Z"
          fill="#EAEAEA"
        />
      </svg>
      <div>
        <svg
          width="38"
          height="37"
          viewBox="0 0 38 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="19.0193"
            cy="18.2029"
            r="17.3432"
            stroke="#EAEAEA"
            strokeOpacity="0.5"
            strokeWidth="1.38745"
          />
          <circle cx="19.019" cy="12.9999" r="2.77491" fill="#EAEAEA" />
          <circle cx="19.019" cy="23.0591" r="2.77491" fill="#EAEAEA" />
        </svg>
      </div>
    </div>
  );
};

export default TopBar;
