// audio files
import dj0 from "./dj0.mp3";
import dj1 from "./dj1.mp3";
import dj2 from "./dj2.mp3";
import dj3 from "./dj3.mp3";
import dj4 from "./dj4.mp3";
import dj5 from "./dj5.mp3";
import dj6 from "./dj6.mp3";
import dj7 from "./dj7.mp3";
import dj8 from "./dj8.mp3";
import dj9 from "./dj9.mp3";
import dj10 from "./dj10.mp3";
import dj11 from "./dj11-edit.mp3";
import dj12 from "./dj12.mp3";
import dj13 from "./dj13-edit.mp3";
import dj14 from "./dj14.mp3";
import dj15 from "./dj15-edit.mp3";
import dj16 from "./dj16.mp3";
import dj17 from "./dj17-edit.mp3";
import dj18 from "./dj18.mp3";
import dj19 from "./dj19.mp3";
import dj20 from "./dj20.mp3";
import dj21 from "./dj21.mp3";
import dj22 from "./dj22.mp3";
import dj23 from "./dj23.mp3";
import dj24 from "./dj24.mp3";

import song2001 from "./2001.mp3";
import songElvis from "./elvis.mp3";
import spaceCover from "./2001.gif";
import elvisCover from "./elvis.gif";

import song from "./song.mp3";

import moviead from "./moviead.mp3";
import intro from "./intro.mp3";
import batman from "./batman.mp3";
import weather from "./weather.mp3";
import summer from "./summer.mp3";
import end from "./end.mp3";
import coke from "./coke.mp3";

// audio thumbnails

import movieCover from "./movie.gif";
import batCover from "./batman.gif";
import cokeCover from "./coke.gif";
import bowieCover from "./bowie.gif";
import start from "./start.gif";
import introCover from "./interlude.gif";
import djCover from "./dj3.gif";
import djCover2 from "./dj.gif";
import djCover3 from "./dj2.gif";
import djCover4 from "./dj4.gif";
import djCover5 from "./dj5.gif";
import weatherCover from "./weather.gif";

import songCover from "./songCover.gif";

export const tracks = [
  {
    title: "GOOD Music",
    src: end,
    author: "Just press play.",
    thumbnail: songCover,
  },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj19,
    author: "GOOD Morning Show",
    thumbnail: djCover,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj10,
    author: "GOOD Morning Show - 4",
    thumbnail: djCover3,
  },
  {
    title: "David Bowie",
    src: dj11,
    author: "Interview",
    thumbnail: bowieCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj12,
    author: "GOOD Morning Show - 5",
    thumbnail: djCover3,
  },
  {
    title: "David Bowie",
    src: dj13,
    author: "Interview",
    thumbnail: bowieCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj14,
    author: "GOOD Morning Show - 6",
    thumbnail: djCover3,
  },
  {
    title: "David Bowie",
    src: dj15,
    author: "Interview",
    thumbnail: bowieCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj16,
    author: "GOOD Morning Show - 7",
    thumbnail: djCover3,
  },
  {
    title: "David Bowie",
    src: dj17,
    author: "Interview",
    thumbnail: bowieCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj18,
    author: "GOOD Morning Show - 8",
    thumbnail: djCover3,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj20,
    author: "GOOD Morning Show - 20",
    thumbnail: djCover3,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj21,
    author: "GOOD Morning Show - 21",
    thumbnail: djCover3,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj22,
    author: "GOOD Morning Show - 22",
    thumbnail: djCover3,
  },

  {
    title: "Drink Coke.",
    src: coke,
    author: "Advertisement",
    thumbnail: cokeCover,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "Illustrated Man",
    src: moviead,
    author: "In theatres now!",
    thumbnail: movieCover,
  },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: start,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj9,
    author: "GOOD Morning Show - 9",
    thumbnail: djCover,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "DJ Popper 🏴‍☠️",
    src: intro,
    author: "GOOD Morning Show - 2",
    thumbnail: djCover2,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj1,
    author: "GOOD Morning Show - 3",
    thumbnail: djCover2,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "DJ Popper 🏴‍☠️",
    src: dj8,
    author: "GOOD Morning Show - 10",
    thumbnail: djCover4,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  // {
  //   title: "DJ Popper 🏴‍☠️",
  //   src: dj2,
  //   author: "GOOD Morning Show",
  //   thumbnail: djCover,
  // },
  // {
  //   title: "Chiquitita",
  //   src: song,
  //   author: "ABBA",
  //   thumbnail: songCover,
  // },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj7,
    author: "GOOD Morning Show - 11",
    thumbnail: djCover5,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj1,
    author: "GOOD Morning Show - 12",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj3,
    author: "GOOD Morning Show - 13",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "Bat Phone",
    src: batman,
    author: "CALL NOW 555 5555",
    thumbnail: batCover,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "Weather Update",
    src: weather,
    author: "Los Angeles",
    thumbnail: weatherCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj4,
    author: "GOOD Morning Show - 14",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj5,
    author: "GOOD Morning Show - 15",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj6,
    author: "GOOD Morning Show - 16",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },

  {
    title: "GOOD Music",
    src: intro,
    author: "Here comes more...",
    thumbnail: introCover,
  },

  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "DJ Popper 🏴‍☠️",
    src: dj1,
    author: "GOOD Morning Show - 17",
    thumbnail: djCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "Bat Phone",
    src: batman,
    author: "CALL IN NOW",
    thumbnail: batCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
  {
    title: "Drink Coke.",
    src: coke,
    author: "Advertisement",
    thumbnail: cokeCover,
  },
  {
    title: "Chiquitita",
    src: song,
    author: "ABBA",
    thumbnail: songCover,
  },
];
