import { useState, useEffect } from "react";

const RequestedSong = ({ request }) => {
  return (
    <div className="requestedSong">
      <div className="songInfo">
        <div className="requestedSong__info__song">{request.song}</div>
        <div className="requestedSong__info__artist">by {request.artist}</div>
      </div>
      <div className="votesInfo">
        <div className="votes">{request.votes}</div>
        <div className="plus">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3232 18.1107L7.92524 18.1107L7.92524 0.110717L12.3232 0.110718L12.3232 18.1107Z"
              fill="#020202"
            />
            <path
              d="M0.870117 11.5653L0.870117 7.16731L18.8701 7.16731L18.8701 11.5653L0.870117 11.5653Z"
              fill="#020202"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default RequestedSong;
