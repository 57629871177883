import { useRef, useState } from "react";
import { tracks } from "../data/tracks";

// import components
import DisplayTrack from "./DisplayTrack";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import TopBar from "./TopBar";
import Marquee from "./Marquee";
import Requests from "./Requests";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TMMWPTBG",
};
TagManager.initialize(tagManagerArgs);

const AudioPlayer = () => {
  // states
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showRequests, setShowRequests] = useState(false);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();

  const handleToggleRequests = () => {
    console.log("showing: ", !showRequests);
    setShowRequests(!showRequests);
  };
  const handleNext = () => {
    let ti = 0;

    if (trackIndex >= tracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(tracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(tracks[trackIndex + 1]);
      ti = trackIndex + 1;
    }

    window.dataLayer.push({
      event: "song_play",
      eventProps: {
        category: "song",
        action: "play",
        label: tracks[ti].author,
        value: tracks[ti].title,
      },
    });
  };

  return (
    <>
      <TopBar />
      <div className="audio-player">
        <div className="inner">
          <DisplayTrack
            {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
              handleToggleRequests,
            }}
          />
          <Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              tracks,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
            }}
          />
          <ProgressBar
            {...{ progressBarRef, audioRef, timeProgress, duration }}
          />
        </div>
      </div>
      {showRequests && <Requests handleToggleRequests={handleToggleRequests} />}

      <Marquee />
    </>
  );
};
export default AudioPlayer;
