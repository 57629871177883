import { BsMusicNoteBeamed } from "react-icons/bs";

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
  handleToggleRequests,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleRequest = () => {
    alert("Coming soon! We promise. Try coming back tomorrow. :)");
  };

  return (
    <div className="trackBox">
      <audio
        src={currentTrack.src}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
      <div className="audio-info">
        <div className="audio-image">
          {currentTrack.thumbnail ? (
            <div className="audio-image-inner">
              <img
                src={currentTrack.thumbnail}
                alt="audio avatar"
                className="rotating2"
              />
              <div className="blackFade"></div>
            </div>
          ) : (
            <div className="icon-wrapper">
              <span className="audio-icon">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div>
        <div className="text">
          <div className="trackText">
            <p className="title">{currentTrack.title}</p>
            <p className="artist">{currentTrack.author}</p>
          </div>

          <div
            className="button gradient-border"
            onClick={handleToggleRequests}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 12L4.31233 12L4.31233 1L7 1L7 12Z" fill="#EAEAEA" />
              <path
                d="M0 8L-9.84466e-08 5.31233L11 5.31233L11 8L0 8Z"
                fill="#EAEAEA"
              />
            </svg>
            REQUEST
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisplayTrack;
